@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/functions";

$padding-left: 20px;
$padding-right: 10px;
$closed-menu-padding: 10px;
$closed-menu-margin: 10px;
$margin-right: 10px;
$offset: 17px;
$sidebar-item-height: 30px;

@mixin hide-menu {
  width: $sidebar-closed-width;

  .avatar .logo {
    display: none;
  }

  .sidebar-menu {
    .sidebar-item-label,
    .copyright .company-name,
    .copyright .version-text,
    .icon-manage {
      display: none;
    }

    .sidebar-label {
      display: none;
    }

    & > ul:before {
      content: "";
      display: block;
      width: 100%;
      opacity: 0.2;
      border-bottom: 1px solid $content-background;
      margin: 14px 0 30px;
    }

    & ul {
      & > li {
        &.has-notification a,
        & a.has-notification {
          .notification-event {
            display: none;
          }

          &:after {
            @include red-dot;
            content: "";
            position: absolute;
            top: -3px;
            right: -3px;
          }
        }

        &.active a,
        &.has-children ul li a {
          padding: 0 $closed-menu-padding;
          margin: 0 $closed-menu-margin;
          border-radius: 5px;
        }
      }
    }
  }
}

.sidebar {
  width: $sidebar-width;
  height: calc(100vh - #{$header-height});
  flex: 0 0 auto;
  transition: width 0.3s ease-out;

  .sidebar-menu {
    @include invisible-scroll;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    transition: height 0.5s linear;

    a {
      position: relative;
      display: block;
      text-decoration: none;
      color: $sidebar-text;
      font-size: 1em;

      .notification-event {
        position: absolute;
        @include border-radius(2px);
        font-size: 14px;
        color: #fff;
        padding: 2px 5px;
        right: 10px;
        display: none;
        height: 0;
        opacity: 0;
        overflow: hidden;
        animation: fadein 2s;
      }

      .notification-event-visible {
        display: block;
        height: auto;
        opacity: 1;

        &:before {
          display: block;
          width: 8px;
          height: 8px;
          background: $danger;
          content: "";
          border-radius: 50%;
          position: absolute;
          right: -15px;
          top: -8px;
        }
      }
    }

    .sidebar-label {
      text-transform: uppercase;
      font-size: 0.78em;
      color: $sidebar-label;
      letter-spacing: 0.1em;
      height: $sidebar-item-height;
      line-height: $sidebar-item-height;
      padding-left: 20px;
      margin-top: 15px;
      text-wrap: nowrap;
    }

    .sidebar-item-label {
      text-wrap: nowrap;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      > li {
        padding: 0;
        margin: 0;

        a {
          padding: 0 $padding-right 0 $padding-left;
          margin-right: $margin-right;
          height: $sidebar-item-height;
          display: flex;
          align-items: center;
          border-bottom: none;

          .icon {
            padding-right: 7px;
            font-size: 18px;
          }
        }

        &.hover,
        &.active {
          a {
            background: $sidebar-menu-active;
            color: $sidebar-menu-active-text;
            @include border-radiuses(0, 5px, 5px, 0);
          }
        }

        &.active {
          font-weight: bold;
        }

        &.has-children {
          cursor: pointer;

          .nav-group-label {
            margin-left: 0;
            @include border-radiuses(0, 5px, 5px, 0);

            .icon-manage {
              position: absolute;
              left: 0;
            }
          }

          &.hover,
          &.opened {
            .nav-group-label {
              background: $sidebar-main;
            }
            ul {
              display: block;
              li {
                a {
                  background: $sidebar-main;
                }
              }
            }
          }

          &.closed {
            .nav-group-label {
              background: $sidebar-menu-active;
            }
          }
          ul {
            display: none;
            li {
              a {
                padding-left: calc(#{$padding-left} + #{$offset});
              }

              &.active a {
                background-color: $sidebar-menu-active;
              }
            }
          }
        }
      }
    }

    .filter {
      color: $alice-blue;

      .filter-fields {
        .filter-section-title {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.6rem;
          letter-spacing: 0.1em;
          padding: 1em 1em 1em 22px;
        }

        .date-input,
        .numeric-input {
          display: flex;

          label {
            line-height: 2em;
            background: rgba(0, 0, 0, 0.4);
            font-size: 0.8em;
            padding-top: 3px;
            padding: 3px 5px 0 5px;
            min-width: 35px;
          }
        }
      }

      .filter-actions {
        display: flex;

        div {
          flex: 1 auto;
          text-align: right;
        }

        div:last-child {
          text-align: left;
        }
      }
    }

    &-close {
      height: 0px;
    }
  }

  .avatar {
    height: $avatar-height;
    background: $avatar-main;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 1.5rem;
    }

    .icon-menu {
      color: #fff;
      display: flex;
    }

    .logo {
      width: 100%;
      height: 100%;
      background: url("../../images/logo_smaller_alter.gif") no-repeat center center;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .sidebar {
    &.sidebar-close {
      position: fixed;
      left: 0;

      & ~ .content-minimal {
        margin-left: $sidebar-closed-width;
      }

      // &:not(:hover) {
      //   @include hide-menu();
      // }

      // &:hover {
      //   width: $sidebar-width;
      //   z-index: 1000;
      //   background-color: $sidebar-main;
      //   @include shadow;
      // }
    }
  }

  .desktop-version .sidebar {
    &.sidebar-close {
      &:not(:hover) {
        @include hide-menu();
      }

      &:hover {
        width: $sidebar-width;
        z-index: 1000;
        background-color: $sidebar-main;
        @include shadow;
      }
    }
  }

  .mobile-tablet-version .sidebar {
    &.sidebar-close {
      @include hide-menu();
    }
  }
}

@media only screen and (min-width: 1200px) {
  .sidebar {
    &.sidebar-close {
      @include hide-menu();
    }
  }
}
