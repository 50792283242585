@import '../../styles/colors.scss';

.dialog {
  .k-window-title {
    text-wrap: wrap;
  }
}

.datarios-dialog {
  &-default {
    .k-dialog {
      max-width: 450px;
    }
  }

  &-large {
    .k-dialog {
      max-width: 800px;
    }
  }

  &-dark {
    .k-dialog {
      background: $content-background;
    }

    .k-dialog-titlebar {
      background: $header-main;
      color: $header-font;
      height: 50px;
    }
  }
}