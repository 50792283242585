/*Mobiles*/
@media screen and (min-width: 0px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

/*Tablets*/
@media screen and (min-width:768px) and (max-width:992px) {
  .container {
    max-width: 100%;
  }
}