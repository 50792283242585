@import "../../styles/colors";
@import "../../styles/variables";

.header {
  height: $header-height;
  background: $header-main;
  color: $header-text;
  width: 100%;
  display: flex;

  .header-menu {
    width: $sidebar-width;
    display: flex;

    &-compact {
      width: auto !important;
    }
  }

  .avatar {
    width: $avatar-width;
    background: $avatar-main;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 1.5rem;
    }

    .logo {
      width: 150px;
      height: 50px;
      background: url("../../images/logo_smaller_alter.gif") no-repeat center center;
      background-size: contain;
    }
  }

  .header-left-controls {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
  }

  .header-center-controls {
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    min-width: 0;
    max-width: 30vw;
  }

  .header-right-controls {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row-reverse;
  }

  .header-tile {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    > .icon {
      color: $header-font;
      font-size: 1.5em;
    }

    > a {
      text-decoration: none;
      color: inherit;
    }

    .account-email {
      color: $header-font;
      text-decoration: underline;
      margin-right: 5px;
      display: flex;
      align-items: center;

      & > .icon {
        font-size: 24px;
      }
    }

    .header-tile-wrapper {
      margin: 10px;
    }

    .header-agenda-selection {
      display: flex;
      align-items: center;
    }

    &.sidebar-btn {
      .icon {
        padding-left: 20px;
      }
    }
  }
}
