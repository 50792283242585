@media screen and (max-width: 768px) {
  .notification-text {
    max-width: 100vw;
  }
}

@media screen and (min-width: 769px) {
  .notification-text {
    max-width: 50vw;
  }
}