@import "./variables.scss";
@import "./colors.scss";
@import "./functions.scss";

:root {
  --app-height: 100vh;
}

/*Phones*/
@media only screen and (max-width: 768px) {
  body {
    overflow: auto;
  }

  .mobile-version {
    &.main-wrapper {
      width: 100vw;
      height: 100vh;
      height: var(--app-height);
      overflow: hidden;
    }

    .sidebar.sidebar-close {
      visibility: hidden;
    }

    .sidebar {
      width: 100vw;
    }

    .sidebar.sidebar-close ~ .content-minimal {
      margin-left: 0;
    }

    .header-agenda-selection .k-input {
      max-width: calc(100vw - 200px);
      min-width: 50px !important;
    }

    .content {
      padding-right: 0;
      height: calc(max(var(--app-height), 400px) - #{$header-height});

      & .panel {
        @include border-radius(0);
      }
    }

    .tabstrip-full-height {
      height: calc(max(var(--app-height), 400px) - 180px);

      .k-content {
        background: $content-background;
      }
    }

    // to prevent Safari from autozoom
    input.k-textbox {
      font-size: 16px;
    }

    .mobile-title {
      color: $header-text;
      height: $mobile-title-height;

      & > div {
        justify-content: center;
      }
    }

    .main-content-height {
      min-height: calc(max(var(--app-height), 400px) - 180px);
    }

    .legal-info {
      flex-direction: column;
      height: auto;
      background: $blue-darker;
      font-size: 1em;
      position: static;

      &-item {
        height: $mobile-title-height;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0 1em;

        &:not(:last-child):after {
          content: "";
        }

        a {
          color: $header-text;
          padding: 0 5px;
        }
      }
    }

    .filter-box .filter-form {
      margin: 0;
    }

    .workflow-merge {
      &-component {
        position: inherit;
      }

      &-form {
        right: 0;
        top: $panel-header-height + $input-height;
        max-width: 100vw;
      }

      &-button-group {
        position: relative;
      }
    }

    // workflow

    .name-of-compact {
      max-width: calc(100vw - 42px);
    }

    .queue-record-row-header {
      max-width: calc(100vw - 45px);
    }

    .attachment-label {
      flex: 0 0 auto;
    }

    .queue-record-row-child {
      max-width: 150px;
      overflow: hidden;
    }

    .chart-controls-title {
      display: none;
    }

    .workflow-chart-controls-settings {
      flex-direction: column;
    }

    .panel-collapsible {
      padding: 5px 15px;
    }

    .settings {
      min-width: auto;
    }

    .myapps-panel {
      width: calc(100vw - 40px);
    }

    .variables-types-panel {
      flex-direction: column;
    }

    .filter-box .filter-form {
      position: initial;
    }

    .d-action-button,
    .thumbnail-button {
      font-size: 18px;
    }

    .thumbnailViewer-menu {
      max-width: 100vw;
      min-width: 100px;
      overflow-x: auto;
    }

    .thumbnailViewer-empty-content {
      width: 100vw;
      height: 50vh;
    }

    .thumbnailViewer-tools {
      margin: 0;
      max-width: 120px;

      div {
        margin-right: 5px;
      }
    }

    .settings {
      .scenario-condition-member {
        height: auto;
      }

      form {
        margin: 0 10px;
      }

      .agreement-steps-content {
        margin: 10px;
      }
    }

    .step {
      flex-direction: column;
      max-width: calc(100vw - 20px);

      &-number {
        margin: 10px auto;
      }

      &-arrow {
        display: none;
      }

      &-container {
        padding-left: 10px;

        .icon-grip-vertical {
          display: none;
        }
      }

      &-header {
        justify-content: space-between;
      }

      &-title {
        width: auto !important;
        max-width: calc(100vw - 90px);

        .k-dropdown .k-input {
          min-width: 150px;
        }
      }

      &-general-info {
        height: auto;
      }

      .local-storage-process-step {
        .floating-label-field,
        .automatic-processing-field {
          max-width: calc(100vw - 40px);
        }
      }

      .workflow-step-form-variable-type {
        width: 40%;
      }
    }

    // grid
    .k-grid-pager {
      width: 100vw;
      max-width: 100%;
      overflow: scroll;
      position: relative;
      z-index: 0;
    }

    .k-notification-group {
      position: absolute;
      bottom: 0;
      max-width: 100vw;
    }

    // info-panel
    .main-info-panel {
      grid-template-columns: 100%;
      row-gap: 20px;
    }
  }
}
