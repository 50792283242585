@import "../../styles/colors.scss";
@import "../../styles/functions.scss";

.history-dropdown-widget {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  z-index: 10;

  .icon-bell {
    color: $header-font;
    font-size: 1.5em;
    position: relative;
  }

  .history-dropdown {
    position: absolute;
    top: 55px;
    right: 0;
    width: 250px;
    max-height: 80vh;
    background: $account-menu;
    color: $account-menu-text;
    @include border-radius(3px);
    @include shadow;
    @include styled-scroll($grey);
    display: none;

    div {
      line-height: 20px;
    }

    &-open {
      display: block;
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &-event-item {
      padding: 5px 20px;
      margin: 0;
      display: flex;
      justify-content: space-between;

      &:hover {
        background: $context-menu-selection;
      }
    }

    &-event-date {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &-event-mark {
      display: flex;
      align-items: center;
      padding-left: 20px;

      &-dot {
        @include color-dot(10px, $blue-darker);
      }
    }

    &-event-item:not(.event-item-unread) {
      opacity: 0.6;

      .history-dropdown-event-mark {
        visibility: hidden;
      }
    }

    &-no-events {
      padding: 20px;
    }
  }

  &.history-dropdown-has-unread {
    .history-dropdown-notification {
      @include red-dot;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}
