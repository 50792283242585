// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=33u0u0kuuDgiWKyotFVCmtEE0n0 */
$blue: rgba(51, 143, 203, 1); //#338fcb
$blue-lightest: rgba($blue, 0.3);
$blue-light: rgba(92, 164, 207, 1); //#5CA4CF
$blue-lighter: rgba(54, 142, 194, 1); //#368EC2
$blue-dark: rgba(6, 97, 151, 1); //#066197
$blue-darker: rgb(39, 69, 83); //#274553
$blue-darkest: rgb(41, 69, 83); //#294553;

$yellow: rgba(255, 190, 12, 1); //#FFBE0C
$yellow-light: rgba(255, 215, 104, 1); //#FFD768
$yellow-lighter: rgba(255, 203, 60, 1); //#FFCB3C
$yellow-dark: rgba(236, 173, 0, 1); //#ECAD00
$yellow-darker: rgba(183, 134, 0, 1); //#B78600

$orange: rgba(255, 71, 12, 1); //#FF470C
$orange-light: rgba(255, 140, 104, 1); //#FF8C68
$orange-lighter: rgba(255, 107, 60, 1); //#FF6B3C
$orange-dark: rgba(236, 57, 0, 1); //#EC3900
$orange-darker: rgba(183, 44, 0, 1); //#B72C00

$black-light: rgba(55, 61, 63, 1); //#373D3F
$black-lighter: rgba(43, 47, 49, 1); //#2B2F31
$black-dark: rgba(31, 35, 36, 1); //#1F2324
$black-darker: rgba(22, 28, 31, 1); //#161C1F

$grey: rgba(41, 69, 83, 0.2);
$grey-light: #c4c4c4;
$grey-lighter: rgba(41, 69, 83, 0.08);
$gray-light: rgba(102, 102, 102, 1); //#666666
$gray-element: #e0e0e0;
$grey-disabled: #e8e8e8;

$alice-blue: #f5f8fa;
$snow: #fffafa;
$gray98: rgba(250, 250, 250, 1); //#fafafa
$white-smoke: #f5f5f5;
$whisper: rgba(234, 234, 234, 1); //#eaeaea
$silver: rgba(192, 192, 192, 1); //#c0c0c0;

$black: #000;
$black-darker-text: rgba(0, 0, 0, 0.8);
$black-text: rgba(0, 0, 0, 0.6);
$black-lighter-text: rgba(0, 0, 0, 0.5);
$black-light-text: rgba(0, 0, 0, 0.38);

$white-2: rgba(255, 255, 255, 0.2);
$white-5: rgba(255, 255, 255, 0.5);
$white-8: rgba(255, 255, 255, 0.8);

$green: #4dbd08;
$light-green: #2bd382;
$orange: #ffa534;
$red: #ff4607;

/* Colors features */
$info: $blue-darker;
$ok: $green;
$success: $light-green;
$warning: $orange;
$danger: $red;
$error: $red;
$dropdown-selected-text: #338fcb;
$dropdown-selected-bg: #00000014;

$sidebar-main: $blue-darker;
$sidebar-label: $white-8;
$sidebar-submenu: $blue-darker;
$sidebar-menu-active: $white-2;
$sidebar-menu-active-text: #fff;
$sidebar-shadow: $blue;
$sidebar-text: $white-8;
$account-menu: #fafafa;
$account-menu-text: $blue-darker;
$context-menu-selection: rgba(0, 0, 0, 0.06);
$dark-text-color: #000;
$text-color: #3e454c;
$link-color: #008eff;
$light-text-color: rgba(255, 255, 255, 0.8);
$field-placeholder-color: #909090;
$field-text-color: $black-darker;
$avatar-main: $blue-darker;
$header-main: $blue-darker;
$header-text: #fff;
$header-tile: $blue;
$header-font: #fff;
$signin-main: $blue-darker;
$primary: $blue;
$primary-text: #fff;
$error-text-color: $danger;
$note-border: #3498db;
$note-background: #f0f7fb;
$content-background: #f4f7f9;
$content-background-alt: #fafbfc;
$input-background: #fff;
$input-border: $black-light-text;
$input-hover-border: #000;
$input-active-border: #2e86c2;
$panel-header-border: $grey;
$panel-header-background: #f4f7f9;
$panel-header-text: $black-text;
$detail-border: #ced4da;
$selected: #ededed;
$action-primary: #3990c9;
$action-secondary: $warning;
$action-thertiary: #bdbfc1;
$action-danger: $danger;
$notification-text: #fff;
$panel-title: #294553;
$primary-element-background: #fff;
$legal-info-background: #fff;
$datarios-button-green-text: #3eb786;
$main-gradient: linear-gradient(
  0deg,
  rgba(40, 66, 84, 1) 0%,
  rgba(39, 41, 79, 1) 53%,
  rgba(62, 40, 78, 1) 79%,
  rgba(23, 12, 31, 1) 100%
);
