@import "./variables.scss";

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

@mixin border-radiuses($topleft, $topright, $bottomright, $bottomleft) {
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
	-webkit-border-top-left-radius: $topleft;
	-webkit-border-top-right-radius: $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-moz-border-radius-topleft: $topleft;
	-moz-border-radius-topright: $topright;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-bottomleft: $bottomleft;
}

@mixin opacity($value) {
	$IEValue: $value*100;
	opacity: $value;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" +$IEValue+")";
	filter: alpha(opacity=$IEValue);
}

@mixin invisible-scroll {
	overflow-y: scroll;
	-ms-overflow-style: none;
	/* Hide scrollbar for IE and Edge */
	scrollbar-width: none;
	/* Hide scrollbar for Firefox */

	&::-webkit-scrollbar {
		display: none;
		/* Hide scrollbar for Chrome, Safari and Opera */
	}
}

@mixin styled-scroll($scroll-color) {
	overflow-y: scroll;
	/* Scrollbar for Firefox */
	scrollbar-color: $scroll-color transparent;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 6px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $scroll-color;
	}
}

@mixin styled-horizontal-scroll($scroll-color) {
	overflow-x: scroll;
	/* Scrollbar for Firefox */
	scrollbar-color: $scroll-color transparent;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		height: 8px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $scroll-color;
	}
}

@mixin styled-placeholder($placeholder-color) {
	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $placeholder-color;
		opacity: 1;
		/* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: $placeholder-color;
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: $placeholder-color;
	}
}

@mixin subsection-header-base {
	font-weight: 600;
	color: $panel-header-text !important;
}

@mixin section-header-base {
	@include subsection-header-base();
	text-transform: uppercase;
}

@mixin subsection-header {
	@include subsection-header-base();
	margin-bottom: 20px;
}

@mixin section-header {
	@include section-header-base();
	margin-bottom: 20px;
}

@mixin truncated($width) {
	width: $width;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: "") {
	-webkit-box-shadow: $top $left $blur $color #{$inset};
	-moz-box-shadow: $top $left $blur $color #{$inset};
	box-shadow: $top $left $blur $color #{$inset};
}

@mixin shadow {
	@include box-shadow(5px, -2px, 13px, 1px, rgba(0, 0, 0, 0.2));
}

@mixin grid-row-shadow {
	box-shadow: 6px 0px 6px 0px #0000001A, inset 0px 2px #0000000F, inset 0px -1px #00000012;
}

@mixin elevation-shadow {
	filter:
    drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.07))
    drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
}

@mixin color-dot($radius, $color) {
	background: $color;
	display: block;
	width: $radius;
	height: $radius;
	border-radius: calc(#{$radius} / 2);
}

@mixin red-dot {
	@include color-dot(8px, $danger);
}

@mixin set_column_widths($col_widths) {
	@each $num, $width in $col_widths {
		.k-grid-header col:nth-of-type(#{$num}) {
			width: $width;
		}

		.k-grid-table col:nth-of-type(#{$num}) {
			width: $width;
		}
	}
}

@mixin dropdown-basic {
	background: $input-background;
	@include border-radius(2px);
}

@mixin dropdown-primary {
	background-color: $blue;
	color: $primary-text;
	text-transform: uppercase;
	font-weight: 600;
}

@mixin component-background($color) {
	background: $color;

	& > * {
		background: $color;
	}
}

@mixin component-color($color) {
	color: $color;

	& > * {
		color: $color;
	}
}