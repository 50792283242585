@import "../../styles/colors.scss";

.icon-button {
  color: rgba(0, 0, 0, 0.6);
  font-size: medium;

  &-disabled {
    opacity: 0.3;
  }

  &.icon-action-primary {
    color: $action-primary;
  }

  &.icon-action-secondary {
    color: $action-secondary;
  }

  &.icon-action-danger {
    color: $action-danger;
  }

  &.icon-action-inactive {
    color: $black-text;
  }

  &.icon-button-link {
    color: $link-color;
    font-size: inherit !important;
    text-transform: lowercase;
  }
}
