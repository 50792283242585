$accent: #338fcb; //#007cc0
$text-color: #333333;
$bg-color: #ffffff;
$base-text: #333;
$base-bg: #f2f2f2;
$hovered-text: #333;
$hovered-bg: #eaeaea;
$selected-text: #ffffff;
$selected-bg: #338fcb; //#007cc0;
$series-a: #008fd3;
$series-b: #99d101;
$series-c: #f39b02;
$series-d: #f05662;
$series-e: #c03c53;
$series-f: #acacac;

$font-size: 14px;
$bounded-panel-max-width: 1100px;

// $sidebar-width-value: 230;
$minimal-width: 800px;
$sidebar-width: 230px;
$sidebar-closed-width: 64px;
$header-height: 60px;
$avatar-height: 60px;
$avatar-width: 200px;
$panel-header-height: 40px;
$tabstrip-top-height: 45px;
$reversed-tabstrip-top-height: 60px;
$legal-info-height: 30px;
$input-height: 40px;
$input-height-sm: 30px;
$mobile-title-height: 30px;

$full-height: calc(max(100vh, 400px));

$content-height: calc(#{$full-height} - #{$header-height});
$main-content-height: calc(#{$content-height} - #{$legal-info-height});
$panel-content-height: calc(#{$main-content-height} - #{$panel-header-height});
$panel-content-with-switcher-height: calc(#{$main-content-height} - #{$panel-header-height});
$tab-content-height: calc(#{$main-content-height} - #{$tabstrip-top-height});
$tab-panel-content-height: calc(#{$tab-content-height} - #{$panel-header-height});
$inner-tab-panel-content-height: calc(#{$tab-content-height} - #{$panel-header-height});
$reverse-tab-content-height: calc(#{$tab-content-height} - #{$reversed-tabstrip-top-height});
$font-family-base: "Open Sans", sans-serif;
$font-family: $font-family-base;
$font-size-base: 14px;
$font-size: $font-size-base;
$input-font-size: $font-size-base;
$checkbox-col-width: 30px;
$queue-record-row-header-width: 55px;
$queue-record-row-menu-width: 125px;