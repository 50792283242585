@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/functions.scss";

.account-menu {
  position: absolute;
  right: 5px;
  top: $header-height - 5px;
  background: $account-menu;
  color: $account-menu-text;
  border-radius: 5px;
  @include shadow;
  display: none;
  z-index: 10000; /* Stay on top */
  min-width: 250px;

  &-opened {
    display: block;
  }

  a {
    display: block;
    text-decoration: none;
    font-size: 1em;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;

      a {
        padding: 1em 1em 1em 25px;
        border-bottom: none;
        color: $account-menu-text;

        .icon {
          padding-right: 7px;
          color: $account-menu-text !important;
        }
      }
    }
  }

  &-header {
    ul {
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 1em;

      li {
        padding: 2px;

        .icon {
          font-size: 2em;
        }
      }
    }

    .username {
      font-weight: 700;
      font-size: 16px;
    }

    & ~ li {
      &:hover {
        background: $context-menu-selection;
      }
    }
  }

  .account-menu-balance {
    font-size: 16px;
    font-weight: 600;
    text-transform: lowercase;
    padding-top: 10px;
  }

  .account-menu-balance-empty {
    color: $warning;
  }

  .account-menu-balance-enough {
    color: $success;
  }

  .account-menu-balance-not-enough {
    color: $danger;
  }
}
