@import "../../styles/variables.scss";
@import "../../styles/functions.scss";
@import "../../styles/colors.scss";

.panel {
  background: $content-background;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  margin: 0;

  &-rounded-top {
    @include border-radiuses(5px, 5px, 0, 0);
  }

  &-rounded-right {
    @include border-radiuses(0, 5px, 0, 0);
  }

  &-rounded-none {
    border-radius: 0;
  }

  &.panel-content-padded {
    padding: 20px;
  }

  height: 100%;
  position: relative;

  .panel-header {
    height: $panel-header-height;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    height: $panel-header-height;
    border-bottom: 1px solid $panel-header-border;
    background: $panel-header-background;
    margin-right: 5px;
    @include border-radiuses(5px, 5px, 0, 0);
  }

  .panel-content {
    height: calc(100% - #{$panel-header-height});
  }

  .actions {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
  }

  .root-actions {
    div {
      background: $gray98;
      color: $gray-light;
      padding: 5px;
      text-align: center;
      border-bottom-left-radius: 4px;
      vertical-align: middle;

      span {
        width: 30px;
        padding: 0 5px;
      }

      span:not(:first-child) {
        border-left: 1px solid $silver;
      }
    }

    &-content {
      position: relative;

      &-overflow {
        overflow-x: scroll;
      }

      &-no-overflow {
        overflow: initial !important;
      }
    }
  }
}

.full-size-version {
  .panel-scrollable {
    overflow-y: scroll !important;
    overflow-x: hidden;
    @include styled-scroll($grey);
  }

  .panel-content-scrollable {
    overflow-y: scroll !important;
    overflow-x: hidden;
    @include styled-scroll($grey);
  }
}