@import "../../styles/colors.scss";

.unexpected-error {
  background: #fff !important;
  display: grid;
  grid-template-rows: auto 15% 15%;
  height: 100%;

  &-logo {
    align-self: center;
    justify-self: center;
    max-height: 40%;
  }

  &-info {
    justify-self: center;
  }

  &-header {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  &-text {
    text-align: center;
    max-width: 500px;
    padding: 20px 0;
  }

  &-buttons {
    text-align: center;
    margin-top: 1.5em;

    & > :not(:last-child) {
      margin-right: 1em;
    }
  }

  &.top-level-error {
    height: 100vh;
    background: $main-gradient !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5%;

    .logo {
      position: absolute;
      top: 2.2em;
      left: 2.2em;
    }

    .unexpected-error {
      &-info {
        color: $primary-text;
        padding-top: 3em;
      }

      &-text {
        font-size: 1.3em;
      }
    }

    .top-right-button {
      position: absolute;
      top: 2.2em;
      right: 2.8em;
    }
  }

  @media screen and (min-width: 1280px) {
    &.top-level-error {
      flex-direction: row-reverse;
      padding: 0 7em 0 10em;

      .unexpected-error {
        &-info {
          padding-right: 7em;
        }

        &-header,
        &-text,
        &-buttons {
          text-align: left;
        }
      }
    }
  }
}
