@import "../../styles/colors.scss";
@import "../../styles/functions.scss";
@import "../../styles/variables.scss";

.anonymous-zone-wrapper {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .anonymous-zone-content {
    display: flex;
    flex: 1 1;
  }

  .anonymous-zone-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5em;

    .datarios-link-button {
      visibility: hidden;
    }
  }

  .anonymous-zone {
    flex: 1 1 60%;
    background: $content-background;
    min-width: 700px;

    .box {
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2em;

      &-wrapper {
        width: 100%;
        max-width: 35em;

        &.two-column {
          max-width: 45em;
        }
      }

      &-header-group {
        padding-bottom: 2em;
      }

      &-header {
        font-size: 1.7em;
        font-weight: 600;
        margin-bottom: 0.25em;
      }

      &-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .actions> :not(:last-child) {
        margin-right: 1em;
      }

      .agreement-links {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .text-box {
        width: 100%;
      }

      .row-margin {
        margin-bottom: 1.5em;
      }
    }

    &-language-selector {
      margin: 0 0 2em auto;
    }
  }

  .general {
    background: $main-gradient;
    flex: 1 1 40%;

    &-content {
      width: 100%;
      height: 100%;
      color: $header-text;
      display: grid;
      grid-template-rows: 25% 25% 50%;
    }

    &-registration {
      padding: 3em;
      display: flex;
      justify-content: space-between;
    }

    &-mission {
      font-weight: 600;
      font-size: 1.7em;
      padding: 0 15%;
      margin: auto;
      text-align: center;
      text-shadow: 0px 2px 2px $black-lighter-text;
    }

    &-display {
      margin: auto;
      text-align: center;

      img {
        width: 80%;
      }
    }
  }

  .search-company-button {
    background: $signin-main;
    color: $primary-text;
  }

  .full-size-button {
    width: 100%;
    height: 100%;
  }

  .agenda-combo-box {
    width: 100%;
    background: $input-background;
  }

  .k-textaria {
    height: 10em;
  }

  .general-no-account {
    height: 40px;
    display: flex;
    align-items: center;
  }
}

/* Small desktop */

@media screen and (max-width: 1200px) {
  .anonymous-zone-wrapper {
    .general-registration {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .general-no-account {
      display: none;
    }
  }
}

/* Tablet */

@media screen and (max-width: 992px) {
  .anonymous-zone-wrapper {
    .general {

      &-registration,
      &-mission,
      &-display {
        visibility: hidden;
      }
    }

    .anonymous-zone-header .datarios-link-button {
      visibility: visible;
    }
  }
}

/* Phones */
@media screen and (max-width: 768px) {
  .anonymous-zone-wrapper.mobile-version {
    height: auto;

    .anonymous-zone-header {
      flex-direction: column;
    }

    .datarios-button-blue {
      transform: translateY(4em);
    }

    .anonymous-zone {
      min-width: 250px;
      display: flex;
      flex-direction: column;

      &-content {
        height: auto;
      }

      .box {
        padding: 1em;
        overflow: scroll;
        height: fit-content;
        flex-grow: 1;

        &-wrapper {
          margin-top: $legal-info-height;
        }
      }

      &-language-selector {
        margin: 1em auto;
      }
    }

    .general {
      display: none;
    }
  }
}