@import '../../styles/variables.scss';

.floating-label-field {
  & > * {
    height: $input-height;
  }

  input {
    height: $input-height;
    width: 100%;
    padding-bottom: 2px !important;
    padding-top: 14px !important;
  }
}
