@import '../../../styles/colors.scss';
@import '../../../styles/functions.scss';

.invitations {
  max-height: 60vh;
  padding-right: 2em;
  overflow-y: scroll;
  @include styled-scroll($grey);

  .invitation-message {
    background: $input-background;
    padding: 1em;
  }

  .invitation-company-name {
    font-weight: 600;
  }
}