@import "../../styles/colors.scss";

.download-archive-package {
  .general-registration {
    width: 100%;
    justify-content: end;
    position: absolute;
    z-index: 1;
  }

  .download-archive-package-main {
    background: $main-gradient;
    flex: 1 1 40%;
    display: flex;
    flex-direction: column;
  }

  &-content {
    color: $header-text;
    height: 100%;
  }

  &-display {
    position: absolute;
    right: 0;
    height: 100%;
    width: 65%;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &-text {
    min-height: 100vh;
    padding: 7em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    line-height: 2.5em;
  }

  &-header {
    font-size: 1.7em;
    margin-bottom: 1em;
  }

  &-main-text {
    font-size: 1.3em;
    margin-bottom: 2em;
  }

  &-buttons .datarios-button {
    font-weight: 600;
    font-size: 1.07em;
    padding: 0.2em 1.2em;
    min-width: fit-content;
    margin-bottom: 2em;
    margin-right: 2em;
  }

  .logo {
    position: absolute;
    left: 2em;
    top: 2em;
  }
}

/* Tablet */

@media screen and (max-width: 992px) {
  .download-archive-package {
    &-display {
      display: none;
    }
  }
}

/*Phones*/
@media only screen and (max-width: 768px) {
  .download-archive-package {
    .download-archive-package-text {
      padding: 2em;
    }

    &-buttons .datarios-button {
      margin-bottom: 0;
      margin-right: 0;
      width: 100%;
      text-align: center;
    }
  }
}