@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.legal-info {
  height: $legal-info-height;
  min-height: $legal-info-height;
  background: $legal-info-background;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $grey;
  position: relative;
  z-index: 10;

  &-item {
    a {
      color: $black-text;
      padding: 0 5px;
    }

    &:not(:last-child):after {
      content: '|';
    }
  }
}
