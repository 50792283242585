/* ------ SPIN ------ */
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  -ms-animation: spin 2s infinite linear; 
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* ------ FADE IN ------ */
.fade-in {
  -moz-animation: fadein 2s; 
  -o-animation: fadein 2s; 
  -webkit-animation: fadein 2s; 
  -ms-animation: fadein 2s; 
  animation: fadein 2s;
}

@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* ------ FADE OUT ------ */

.fade-out {
  -moz-animation: fadeout 2s; 
  -o-animation: fadeout 2s; 
  -webkit-animation: fadeout 2s; 
  -ms-animation: fadeout 2s; 
  animation: fadeout 2s;
}

@-moz-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@-o-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; position: absolute; left: -999px; }
}