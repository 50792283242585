@import "../../styles/colors.scss";
@import "../../styles/functions.scss";
@import "../../styles/variables.scss";

.agenda-selection-authenticated {
  .select-agenda-value-render {
    .select-agenda-list-item-right {
      .handshake-component {
        color: #fff;
      }
    }
  }
}

.select-agenda-value-render {
  .select-agenda-list-item-right {
    .handshake-component {
      margin: 0 0 0 10px;
    }
  }
}

.select-agenda-list-item {
  display: flex;
  width: 100%;

  &-left {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  &-right {
    flex: 0 1 auto;
    display: flex;
    align-items: center;

    .handshake-component {
      background: $blue-lightest;
      border-radius: 5px;
      padding: 0 5px;
      color: $link-color;
      line-height: 29px;
      height: 29px;
      min-width: 55px;
      display: flex;

      .icon {
        flex: 1 1 auto;
        text-align: left;
      }
      
      .text {
        flex: 1 1 auto;
        text-align: right;
      }
    }
  }
}