@import "../../styles/colors";
@import "../../styles/variables";

.image-dropdown {
  width: fit-content !important;
  display: flex;
  align-items: center;

  .image-dropdown-value {
    display: flex;

    &-text {
      margin-left: 5px;
    }
  }

  &.image-dropdown-light {
    .image-dropdown-value-text{
      color: $header-text;
    }
  }

  &.image-dropdown-blue {
    border: 1px solid $blue;
    border-radius: 2px;
    height: $input-height;

    .image-dropdown-value-text {
      color: $blue;
    }
  }

  &.image-dropdown-dark {
    background: transparent !important;
    border: 1px solid $black-light-text !important;
    border-radius: 2px;
    height: $input-height;
  }

  &.image-dropdown-compact {
    .image-dropdown-value-text {
      text-transform: uppercase;
    }
  }
}

.image-dropdown-item {
  display: flex;

  &-text {
    margin-left: 5px;
  }
}