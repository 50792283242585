@import "colors";
@import "functions";
@import "variables";

// Kendo components style customization
$kendo-border-radius: 2px;
$kendo-font-size-lg: 14px;
$kendo-font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif;

$kendo-color-primary: $blue;
$kendo-color-secondary: $blue-darker;
$kendo-color-tertiary: $action-thertiary;
$kendo-color-info: $info;
$kendo-color-success: $success;
$kendo-color-warning: $warning;
$kendo-color-error: $danger;

$kendo-button-border-width: 1px;
$kendo-button-border-radius: 2px;
$kendo-button-line-height: 1;
$kendo-button-font-size: 14px;
$kendo-button-sm-font-size: 14px;
$kendo-button-md-font-size: 14px;
$kendo-button-lg-font-size: 14px;
$kendo-button-md-padding-x: 10px;
$kendo-button-md-padding-y: 0;
$kendo-button-md-calc-size: 16px;

$kendo-grid-header-padding-x: 5px;
$kendo-grid-header-padding-y: 0;
$kendo-grid-cell-horizontal-border-width: 0;
$kendo-table-md-cell-padding-x: 0;
$kendo-table-md-cell-padding-y: 0;
$kendo-grid-bg: $content-background;
$kendo-grid-alt-bg: $content-background-alt;
$kendo-grid-header-menu-icon-spacing: 0;
$kendo-grid-header-font-size: 12px;

$kendo-picker-bg: $input-background;
$kendo-picker-border: $input-border;
$kendo-picker-flat-border: transparent;
$kendo-picker-flat-text: $primary-text;

$kendo-tabstrip-border-width: 0;

$kendo-input-bg: $input-background;
$kendo-input-padding-x: 16px;
$kendo-input-padding-y: 0;
$kendo-input-sm-padding-y: 0;
$kendo-input-md-padding-y: 0;
$kendo-input-clear-value-opacity: 0;
$kendo-input-sm-line-height: 2;
$kendo-input-md-line-height: 2.7;

$kendo-list-item-selected-bg: $dropdown-selected-bg;
$kendo-list-item-selected-text: $dropdown-selected-text;

$kendo-floating-label-scale: 1;
$kendo-floating-label-focus-scale: 0.65;
$kendo-floating-label-font-size: 16px;
$kendo-floating-label-offset-x: 16px;
$kendo-floating-label-offset-y: 30px;
$kendo-floating-label-focus-offset-x: 16px;
$kendo-floating-label-focus-offset-y: 16px;
$kendo-floating-label-text: $black-lighter-text;
$kendo-floating-label-focus-text: $kendo-color-primary;

$kendo-dialog-buttongroup-padding-x: 20px;
$kendo-dialog-buttongroup-padding-y: 20px;
$kendo-dialog-buttongroup-border-width: 0px;
$kendo-dialog-button-spacing: 20px;
$kendo-window-inner-padding-x: 20px;
$kendo-window-inner-padding-y: 20px;
$kendo-window-titlebar-padding-x: 20px;

$kendo-chip-solid-bg: $gray-element;
$kendo-chip-solid-text: $black-text;
$kendo-chip-solid-selected-bg: $blue-lightest;
$kendo-chip-solid-selected-text: $blue;
$kendo-chip-md-padding-x: 8px;
$kendo-chip-list-sizes: (
  sm: 4px,
  md: 8px,
  lg: 8px
);

$kendo-pager-bg: $content-background;
$kendo-pager-item-border-radius: 0;
$kendo-pager-md-item-min-width: fit-content;
$kendo-pager-md-dropdown-width: fit-content;
$kendo-pager-number-border-radius: 2px;

$kendo-checkbox-md-size: 12px;
$kendo-checkbox-border: $blue;
$kendo-checkbox-checked-bg: transparent;
$kendo-checkbox-checked-text: $blue;
$kendo-checkbox-checked-border: $blue;

$kendo-input-font-size: 14px;

/* Kendo components - for production is better to use prebuild CSS styles.
Use Theme buidler to generate all.css file and import by command.
@import 'all.css'; */
@import "~@progress/kendo-theme-material/dist/all";

.k {
  &-primary:disabled {
    color: rgba(0, 0, 0, 0.38) !important;
    background-color: #e0e0e0 !important;
  }

  &-grid {
    table {
      width: 100% !important;
    }

    .k-table-th {
      height: 28px;
      font-weight: 500;
      color: $black-text;
    }

    .k-table-th:first-child {
      display: flex;
      align-items: center;
    }
  }

  &-label {
    height: fit-content;
  }

  &-searchbar {
    padding-left: 10px;
  }

  &-dialog-actions {
    padding-top: 0;
  }

  &-input-values {
    padding-left: 0 !important;
  }

  &-input-value-text {
    display: flex;
    align-items: center;
  }

  &-grid {
    height: 100%;
    font-family: $font-family-base;

    &-actions {
      background: $blue;
      border: 1px solid #fff;
      padding: 10px;
      @include border-radiuses(2px, 2px, 0, 0);

      &-button {
        background: transparent;
        color: #fff;
        padding: 0px 10px;
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.08);
      }

      &-button:last-child {
        border-right: 0px;
      }
    }

    &-column-menu {
      position: relative !important;
      z-index: 0 !important;
    }

    .k-cell-date {
      text-align: center !important;
    }

    .k-cell-currency {
      text-align: right;
    }

    .k-cell-centered {
      text-align: center;
    }

    .k-cell-icon {
      text-align: center;
    }

    th {
      background: #fff;
    }

    th,
    td {
      padding: 3px 5px;
    }

    .k-table-th,
    td {
      border-bottom-width: 0;

      & > .k-checkbox {
        vertical-align: middle;
      }
    }

    .k-master-row > td {
      border-top: 1px solid $grey;
    }

    .k-table-row:hover {
      background-color: $content-background !important;
      @include grid-row-shadow;
    }

    .k-table-row.k-table-alt-row:hover {
      background-color: $content-background-alt !important;
    }
  }

  &-i-filter {
    color: #333 !important;
  }

  &-textbox {
    background: $input-background;
    padding-left: 1em !important;
    border-radius: 2px 2px 0px 0px;
    border-bottom: solid 1px $input-border;

    &-container:after {
      height: 0;
    }

    &-container > .k-label {
      left: 15px;
    }

    &:hover {
      border-bottom: solid 1px $input-hover-border;
    }

    &:focus {
      border-bottom: solid 1px $input-active-border;
    }

    &:disabled {
      border-bottom: solid 1px $input-border;
    }
  }

  &-fieldset {
    padding: 0 !important;
  }

  &-textarea {
    width: 100%;
    background: #fff;
  }

  &-notification-group {
    margin: 15px;
    bottom: $legal-info-height;
  }

  &-notification {

    &-success,
    &-error,
    &-warning,
    &-info {
      color: $notification-text;

      .notification-message,
      .k-icon {
        color: $notification-text;
      }
    }
  }

  &-widget {
    .k-content {
      .k-animation-container {
        width: 100%;
        border-radius: 0;
      }
    }
  }

  &-tabstrip {
    &-items {
      border-color: $blue;
      max-width: 100%;
      overflow: scroll;
      @include invisible-scroll;

      .k-default {
        color: rgba(255, 255, 255, 0.8);
      }

      .k-active {
        color: $blue-darker !important;
        background-color: $content-background !important;
      }

      & > .k-item:not(.k-active) {
        opacity: 1;
      }
    }

    > .k-content {
      background-color: transparent;
      border: 0;
      padding: 0;
    }

    .k-animation-container {
      width: 100%;
    }
  }

  &-tabstrip {
    & &-items {
      border-bottom-width: 0px;
      text-transform: none;
      height: $tabstrip-top-height;
      @include border-radiuses(5px, 5px, 0, 0);
      background: #e0f7f91f;

      .k-item {
        @include border-radiuses(5px, 5px, 0, 0);
        background: transparent;
        height: 100%;

        &:not(.k-state-disabled):hover {
          color: #fff;
        }

        &:not(.k-state-active) {
          color: rgba(255, 255, 255, 0.38);
        }

        &:after {
          border-bottom-width: 0;
        }

        &.k-state-active {

          .k-link,
          .tab-link {
            color: $blue-darker;
          }
        }
      }
    }

    & &-items-wrapper {
      width: fit-content;
    }
  }

  &-editor {
    .k-tool-group {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  &-numerictextbox {
    background: $input-background;

    .k-numeric-wrap {
      border-style: none;
      padding: 0 10px;
    }

    input {
      text-align: right;
    }
  }

  &-selection-multiple &-selected-icon-wrapper {
    display: none;
  }

  &-pager &-button {
    padding-block: 0;
  }
}

.k-button-text {
  display: flex;
  align-items: center;
  gap: 5px;

  .icon:before {
    margin: 0;
  }
}

.full-size-version .k-tabstrip.tabstrip-full-height {
  .k-tabstrip-top > .k-tabstrip-item {
    height: 45px;
  }

  > .k-content {
    height: calc(#{$main-content-height} - 45px);
    overflow: hidden;
  }
}

.full-tabstrip {
  .k-content {
    background-color: inherit !important;
    border: 1px !important;
    padding: inherit !important;
  }
}

.full-k-content {
  padding: 0 !important;
}

.reverse-tabstrip {
  $reverse-active-color: #294553;

  .k-tabstrip .k-tabstrip-items {
    height: $reversed-tabstrip-top-height;
    border-bottom: solid 1px $grey;
    background: $content-background !important;
    width: 100%;

    .k-item {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;

      &:not(.k-state-disabled):hover {
        color: $reverse-active-color;
      }

      &.k-active {
        border-bottom: solid 3px $reverse-active-color !important;
        color: $reverse-active-color !important;
        font-weight: 600;

        .k-link {
          margin-bottom: -3px;
        }
      }

      &:after {
        border-bottom-width: 0;
      }
    }
  }
}

.k-switch-label-on {
  left: 10px;
}

.k-switch-label-off {
  right: 10px;
}

// change standard icon

.k {
  &-i-success::before {
    font-family: "datarios";
    content: "\e700";
  }

  &-i-error::before {
    font-family: "datarios";
    content: "\e701";
  }

  &-i-warning::before {
    font-family: "datarios";
    content: "\e702";
  }

  &-i-info::before {
    font-family: "datarios";
    content: "\e703";
  }

  &-i-arrow-n::before {
    font-family: "datarios";
    content: "\e704";
  }

  &-i-arrow-s::before {
    font-family: "datarios";
    content: "\e705";
  }

  &-plus:before {
    content: "\e005";
  }

  &-svg-i-plus {
    &::after {
      font-family: "datarios";
      content: "\e893";
    }

    & > svg {
      visibility: hidden;
    }
  }

  &-svg-i-minus {
    &::after {
      font-family: "datarios";
      content: "\e804";
      font-size: 13px;
    }

    & > svg {
      visibility: hidden;
    }
  }
}

.k-dialog {
  max-width: 450px;
}

.k-upload {
  .k-button {
    @extend .general-button;
  }

  &-selected {
    color: #fff;
  }

  &-selected,
  .k-clear-selected {
    text-transform: capitalize;
  }

  .k-button-text span {
    text-transform: none;
  }
}